#competences {
  position: relative;
  z-index: 2;
  background-image: radial-gradient(circle, $skills-dots, $skills-dots 1px, #fff 1px, #fff);
  background-color: #fff;
  background-size: 28px 28px;
  padding-top: $section-padding-top;
  padding-bottom: $section-padding-bot;
  perspective: 500px;

  .about {
    h2, h3 {
      font-family: $font-family-base;
      font-weight: $font-weight-base;
      letter-spacing: 0 !important; }
    .title {
      font-size: 2.9rem; }
    .subtitle {
      font-size: 2.1rem; }
    b {
      font-weight: bold; }
    .wave {
      height: 40px;
      animation-name: wave-animation;
      animation-duration: 2.5s;
      animation-iteration-count: infinite;
      transform-origin: 70% 70%;
      display: inline-block;
      vertical-align: baseline; } }

  .tech .name {
    font-weight: bold;
    font-size: 1.1em; }

  .card {
    font-weight: bold;
    text-align: center;
    width: fit-content;
    height: 140px;
    padding: 20px;
    background-size: cover;
    position: relative;
    transition-duration: 300ms;
    transition-property: transform, text-shadow;
    transition-timing-function: ease-out;
    transform: rotate3d(0, 0, 0, 0);

    img {
      filter: drop-shadow(0 0 0 #00000044);
      transition-duration: 300ms;
      transition-property: filter;
      transition-timing-function: ease-out; }

    &:hover {
      img {
        filter: drop-shadow(5px 10px 5px #00000044); }
      text-shadow: 5px 10px 5px #00000044; }

    .glow {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0; } } }

@keyframes wave-animation {
  0% {
    transform: rotate( 0.0deg); }
  10% {
    transform: rotate(14.0deg); }
  20% {
    transform: rotate(-8.0deg); }
  30% {
    transform: rotate(14.0deg); }
  40% {
    transform: rotate(-4.0deg); }
  50% {
    transform: rotate(10.0deg); }
  60% {
    transform: rotate( 0.0deg); }
  100% {
    transform: rotate( 0.0deg); } }
