#contact {
  padding-top: $section-padding-top;
  padding-bottom: $section-padding-bot;

  textarea {
    min-height: 200px;
    padding-top: 15px; }

  //.alert
 }  //  display: none
