#experience {
  .content {
    padding-top: $section-padding-top;
    padding-bottom: $section-padding-bot;

    h3 {
      color: #fff; } }

  .xp-panel {
    background-color: $experience-panel;
    backdrop-filter: blur(2.5px);
    -webkit-backdrop-filter: blur(2.5px);
    border-radius: 4px;
    border-left-width: 4px;
    border-left-style: solid;
    z-index: 1;
    transition: box-shadow .2s ease-in-out, left .1s ease-in-out;
    box-shadow: 1px 2px 2px 0 $experience-panel-shadow;
    position: relative;
    .xp-description {
      font-size: 15.5px;
      letter-spacing: 0.01rem;
      line-height: 25px; }

    @media (min-width: 767.98px) {
      &:hover {
        box-shadow: 4px 8px 15px 0 $experience-panel-shadow; } }
    h3 {
      font-size: 1.5rem; }

    .timespan {
      font-style: italic; }
    .tags {
      margin-right: -10px;
      .tag {
        border-width: 2px;
        border-style: solid;
        height: 25px;
        line-height: 18px;
        padding: 2px 7px;
        border-radius: 16px;
        font-size: 13px;
        margin-right: 10px;
        font-family: $headings-font-family; } }
    .techno {
        font-family: $headings-font-family;
        font-size: 15px; }
    a {
      color: #fff !important;
      text-decoration: underline; } }

  #particles {
    position: sticky;
    top: 0;
    width: 100%;
    height: 100vh;
    margin-top: -100vh;
    background: $experience-bg; } }
